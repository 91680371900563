#import {
  height: 100%;
  padding: 3rem;

  & .dropzone {
    max-width: 100%;
    min-height: 300px;
    border: 1px dashed var(--color-red);
    border-radius: .5rem;
    background: rgba(0,0,0,.02);
    margin-bottom: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: #666;
    padding: 1rem;
    text-align: center;

    &:hover {
      background: rgba(0,0,0,.03);
    }

    & svg {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: var(--color-red-dark);
    }

    &-danger {
      color: red;
      background-color: rgba(255,0,0,0.05);
      border-color: red;

      &:hover {
        background-color: rgba(255,0,0,0.06);
      }
    }

  }
}