.dashboard {
  width: 85%;
  padding: 10px 30px;
  min-height: 50vh;
  margin: 50px auto;
  border: 1px dashed var(--color-red);
  border-radius: 5px;

  .card-header .title {
    text-align: center;
    padding-top: 10px;
  }

  .card-header div {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 992px) {
  .dashboard {
    width: 95%;
    padding: 10px 0px;
  }

  .dashboard .card-header h2 {
    font-size: 1.8rem;
  }
  
}
