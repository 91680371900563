.sidebar {
  display: block;
  box-shadow: 3px 4px 8px rgba(0,0,0,0.1);
  padding: 2rem 1rem;

  & ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    & li {
      display: flex;

      & .adt-btn {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .sidebar {
    width: 100%;
    height: calc(100vh - 5.2rem);
    position: fixed;
    left: 0;
    right: 0;
    top: 5.2rem;
    bottom: 0;
    background: white;
    z-index: 1;
    animation: fadeToRight .5s linear;
  }

  @keyframes fadeToRight {
    0% { opacity: 0; transform: translateX(-100vh); }
    50% { opacity: 0; transform: translateX(-50vh); }
    100% { opacity: 1; transform: translateX(0); }
  }
}