.login {
  background: linear-gradient(-150deg, #752316, #b2342a 80%, #dc3545 120%);
  animation: gradienter 15s ease-in-out infinite;
  background-size: 400%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  & section {
    width: 30%;
    margin: 0 auto;
    z-index: 1;
  }

  & img {
    width: 285px;
    margin-bottom: 30px;
  }

  & section form label {
    color: #fff;
  }

  & section form input {
    margin: 8px 0px 15px 0px;
    padding: 12px 16px;
  }

  & section form button {
    width: 100%;
    margin: 30px 0 0;
    border-color: #fff;
    color: #fff;
    padding: 12px 16px;
  }

  .adt-btn--red_reversed:hover {
    color: #fff;
    opacity: 0.8;
  }

  & .box-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;

    & li {
      position: absolute;
      display: block;
      list-style: none;
      width: 25px;
      height: 25px;
      background-image: url("../../../assets/images/triangulo.png");
      background-size: 100% 100%;
      animation: animate 20s linear infinite;
      bottom: -150px;

      &:nth-child(1) {
        left: 86%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        left: 12%;
        width: 30px;
        height: 30px;
        animation-delay: 1.5s;
        animation-duration: 10s;
      }
      &:nth-child(3) {
        left: 70%;
        width: 100px;
        height: 100px;
        animation-delay: 5.5s;
      }
      &:nth-child(4) {
        left: 42%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 15s;
      }
      &:nth-child(5) {
        left: 65%;
        width: 40px;
        height: 40px;
        animation-delay: 0s;
      }
      &:nth-child(6) {
        left: 15%;
        width: 110px;
        height: 110px;
        animation-delay: 3.5s;
      }
    }
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(-800px) rotate(360deg);
      opacity: 0;
    }
  }

  @media only screen and (max-width: 992px) {
    max-width: 100%;

    & section {
      width: 90%;
    }
  }
}
