.topbar {
  background-color: var(--color-red);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    width: 200px;
  }

  & svg {
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
  }

  & svg:first-child {
    display: none;
  }

  @media only screen and (max-width: 992px) {
    & svg:first-child {
      display: block;
    }
  }
}
