@import url(../../../node_modules/ademidentity/scss/theme.scss);

* {box-sizing: border-box;}
html,body {overflow-x: hidden;font-size: 16px;scroll-behavior: smooth; max-width: 2000px;}

ul {padding: 0;}
h1, h2, h3, h4, h5, h6, .adt-title { margin-block-start: 0rem;margin-block-end: 0.3rem; }

::-webkit-scrollbar {width: 10px;}
::-webkit-scrollbar-track {background: #2e2e2e;}
::-webkit-scrollbar-thumb {background: #111;border-radius: 30px;border: 1px solid var(--color-red);}
::-webkit-scrollbar-thumb:hover {background: #000;transition: .5s;}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
input:-webkit-autofill:invalid,
input:-webkit-autofill:placeholder-shown,
input:-internal-autofill-selected,
input:-webkit-autofill:-internal-autofill-selected {
  -webkit-transition-delay: 99999999s;
  transition-delay: 99999999s;
  background-color: transparent!important;
  color: #fff!important;
  appearance: none;
}

.swal2-styled.swal2-confirm {
  width: 105px;
  border-radius: 20px !important;
}

.swal2-styled.swal2-default-outline:focus {
  box-shadow: none !important;
}

.adt-btn {padding: 10px 26px;}