.TicketValidation {
  margin: 0 auto;

  & section {
    max-width: 80%;
    min-height: 300px;
    border: 1px dashed var(--color-red);
    margin: 47px auto 0;
    border: 1px dashed var(--color-red);
    box-shadow: 3px 4px 8px rgb(0 0 0 / 10%);
  }

  & section form {
    padding: 50px;
  }

  & section form input {
    max-width: 500px;
    border-radius: 10px;
    margin: 15px 0;
  }

  .message {
    max-width: 550px;
    border-radius: 10px;
    padding: 0 0 50px 55px;
  }

  .message .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    background-color: rgba(92, 184, 92, 0.2);
    border: 1px solid #5cb85c;

    & p {
      color: #043726;
      font-weight: 700;
    }
  }

  .message .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgba(255, 0, 0, 0.05);
    border: 1px solid #b2342a;

    & p {
      color: #b2342a;
      font-weight: 700;
    }
  }

  .message p {
    margin: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .TicketValidation section {
    max-width: 90%;
    padding: 9px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
  }

  .TicketValidation section form {
    padding: 25px 0;
  }

  .TicketValidation section form input {
    margin: 20px 0;
  }

  .TicketValidation .message {
    padding: 0 0 25px 0;
  }
}
