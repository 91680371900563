#Alert {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(178,52,42,.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: show 1s linear;
  color: #fff;

  h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }


  a {
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      text-decoration: underline;
      transition: 1s;
    }
  }

  p {
    cursor: pointer;
  }

  @keyframes show {
    from { opacity: 0; }
    to { opacity: 1; }
  }

}