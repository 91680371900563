#main {
  height: 100vh;

  & article {
    height: calc(100vh - 5.1rem);
  }

  @media only screen and (max-width:992px) {
    & article aside:last-child {
      width: 100%;
    }
    
    .TicketValidation .message .success p {
      text-align: center;
    }
  }
}